import React, { Component } from "react";
import TableIcon from "../TableIcons/TableIcons";
import { ColumnBorder, RacingStripe } from "./TableDecorations";
//@ts-ignore
import "./KpiTable.scss"

export interface KpiTableProps {
  height?: any;
  title?: any;
  kpis?: any[]
}

export interface KpiTableState {
 rowCount: number;
 templateAreas: any;
}

class KpiTable extends React.Component<KpiTableProps, KpiTableState> {
  constructor(props: KpiTableProps) {
    super(props);
    this.state = {
      rowCount: 0,
      templateAreas: "",
    };
  }

  componentDidMount = () => {
    const kpiArray = this.props.kpis || [];

    const kpiArrayLength: any = kpiArray.length;
    //counting how many rows we have recieved from the data
    if (this.state.rowCount !== kpiArrayLength) {
      this.setState({rowCount: kpiArrayLength + 1}) 
      //the +1 adds a little automatic spacing at the bottom. it's not a real kpi row
    }

    const areaArray: any = [];

    kpiArray.forEach((row, index) => {
    const rowNumber = index + 1;
    const area = `"row${rowNumber}-label row${rowNumber}-yoy row${rowNumber}-target spacer"`
    areaArray.push(area);    
    }) //creating an array of strings that describes the CSS Grid template

    this.generateGridTemplateAreas(areaArray);

  };



  generateGridTemplateAreas = (areaStrings: any) => {
    let tableGrid: any = {};
    const gridStyleString = areaStrings.join(" ");
    tableGrid["gridTemplateAreas"] = gridStyleString;
    this.setState({templateAreas: tableGrid.gridTemplateAreas});
  } //creating the style object




  render() {

    const performanceColor = {
      up: "#8FC113",
      neutral: "#666666",
      down: "#F25F4A"
    };

    const arrowDirection = {
      up: "up",
      neutral: "neutral",
      down: "down",
    };



    var stripeStyle = {
      boxShadow: "inset -5px 5px 1px" + performanceColor.neutral, //I'm going to refactor this soon to reflect kpi performance
      gridArea: `1 / 1 / ${this.state.rowCount + 2} / 5` //racing stripe placement
    };

    var gridRows = {
      gridTemplateRows: `repeat(${this.state.rowCount}, auto)`
    }

    var tableGrid = {
      gridTemplateRows: `repeat(${this.state.rowCount}, auto)`,
      gridTemplateAreas: `"head-label head-yoy head-target spacer" ${this.state.templateAreas}`
    }

  



    return (
      <div className="kpi-table" data-table="kpi" style={tableGrid}>

        <RacingStripe style={stripeStyle} />

        <TableHeading
          title={this.props.title}
          yoyColor={performanceColor.neutral}
          targetColor={performanceColor.neutral}
        />

        {(this.props.kpis || []).map((kpi, index) => (

          <TableRow key={kpi.row} row={index + 1} label={kpi.label}>

            <Cell
              for={index + 1}
              column="yoy"
              arrow={kpi.yoy >= 0 ?arrowDirection.up : arrowDirection.down}
              color={kpi.yoy >= 0 ?performanceColor.up : performanceColor.down}
              percent={kpi.yoy}
            />

            <Cell
              for={index + 1}
              column="target"
              arrow={kpi.target >= 0 ?arrowDirection.up : arrowDirection.down}
              color={kpi.target >= 0 ?performanceColor.up : performanceColor.down}
              percent={kpi.target}
            />

          </TableRow>

        ))}

        <ColumnBorder column="yoy" placement={this.state.rowCount + 2} />
        <ColumnBorder column="target" placement={this.state.rowCount + 2} />
      </div>
    );
  }

}

export default KpiTable




const TableHeading = (props: any) => {

  return (
    <>
      <div data-row="heading" data-column="label" className="kpi-table_title">
        <h2>{props.title}</h2>
      </div>
      <div data-row="heading" data-column="yoy" className="kpi-table_heading-icon">
        <TableIcon icon="yoy" color={props.yoyColor} />
      </div>
      <div data-row="heading" data-column="target" className="kpi-table_heading-icon">
        <TableIcon icon="target" color={props.targetColor} />
      </div>

    </>
  )

};



const TableRow = (props: any) => {
  const handleRowClick = (selectedItem: any) => {
    console.log(selectedItem);
  }
  return (
    <>
      <div 
        data-row={props.row} 
        data-column="label" 
        className="kpi-label" 
        key={props.row}
        style={{gridArea: `row${props.row}-label`}} 
        onClick= {e => handleRowClick(props)}
        >
         {props.label}
      </div>
      {props.children}
    </>
  )
};

const Cell = (props: any) => {

  return (

    <div 
      data-row={props.for} 
      data-column={props.column} 
      style={{ color: props.color, 
              gridArea: `row${props.for}-${props.column}` }}
      >
      <TableIcon icon={props.arrow} />
      <span>{props.percent}</span>
    </div>

  )

};
