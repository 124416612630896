import React from 'react'
import { useSpring, a, config } from 'react-spring'

import './KpiCard.scss'

import KpiTable from '../../../KPI/KpiTable/KpiTable'

export interface KpiCardProps {
    data?: any;
    title?: string;
  }

  class KpiCard extends React.Component<KpiCardProps, any> {
    constructor(props: KpiCardProps) {
        super(props);
        this.state = {
        };

  }
  render() {
    return (
      <>
      {/* <h2>{this.props.data ? this.props.data.title : ''}</h2> */}
        <KpiTable 
          title={this.props.data ? this.props.data.title : ''} 
          kpis={this.props.data ? this.props.data.kpis : []} 

          />
      </>
    )
  }
}

export default KpiCard
