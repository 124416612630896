import React from 'react'
import "./KpiProgressBar.scss";

export interface KpiProgressBarProps {
    progress: any;
    activecolor?: any;
    remainingcolor?: any;
    style?: object;
    className?: any;
    leftlabel?: any;
    rightlabel?: any;
    labelbgcolor?: any;
    goalWidth?: any;
    goal?: any;

}

export interface KpiProgressBarState {
  progressPercent: number;
}

class KpiProgressBar extends React.Component<KpiProgressBarProps, KpiProgressBarState> {
    constructor(props: KpiProgressBarProps) {
        super(props);
        this.state = {
          progressPercent: 0,
        };
    }

    componentDidMount = () => {

      const progressProps: number = this.props.progress;

      if (this.props.progress > 0) {

          this.setState({progressPercent: progressProps});

      }

    }

    randomTestPercent = () => {
      //this is just for testing the UI when data is unavailable
      let randomPercentNumber: number = Math.random() * (99 - 2) + 2;
      return randomPercentNumber;
    }


    render() {


        //let minlimit = 10;
        //let minvalue = this.props.progress >= minlimit ? minlimit : this.props.progress;

        //let skew = this.props.progress >= minlimit ? 'skewX(13deg)' : 'skewX(0deg);';
      //  let activeskew = this.props.progress >= minlimit ? 'skew(0deg, 0deg)' : 'skew(0deg, 0deg)';



        return (
            <div className={`kpiprogress-container ${this.props.className}`}
                  style={{ ...this.props.style }}>

                  <div data-goal={this.props.goal}
                       style={{width: this.props.goalWidth,
                          backgroundColor: this.props.remainingcolor}}>

                      <div className="kpi-progress-inner">
                        <div data-progress={this.state.progressPercent} className="kpi-progress-fill" style={{
                            backgroundColor: this.props.activecolor,
                            width: `${this.state.progressPercent}%`
                        }}></div>
                        </div>



                    </div>

                    <div className="label-left" style={{backgroundColor:this.props.labelbgcolor}}>
                   <div className="left-value">{this.props.leftlabel}</div>
                    </div>
                    <div className="label-right" style={{backgroundColor:this.props.labelbgcolor}}>
                    <div className="right-value">{this.props.rightlabel}</div>
            </div>

            </div>

        );
    }
}

export default KpiProgressBar;
