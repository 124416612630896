//import React, { Component } from "react";
import * as React from "react";

import "./KpiTable.scss"

//grid-row-start / grid-column-start / grid-row-end /grid-column-end


//.racing-stripe {
 // grid-area: 1 / 1 / 8 / 5;
//}

//.column-border.yoy {
 // grid-area: 1 / 2 / 8 / 3;
//}

//.column-border.target {
 //  grid-area: 1 / 3 / 8 / 4;
//}

const ColumnBorder = (props: any) => {

  const border = {
    yoy: (<div className="column-border yoy" style={{gridArea: `1 / 2 / ${props.placement} / 3`}}></div>),
    target: (<div className="column-border target" style={{gridArea: `1 / 3 / ${props.placement} / 4`}}></div>),
  };

    let renderBorder;

    switch (props.column) {
      case "yoy":
        renderBorder = border.yoy;
        break;

      case "target":
        renderBorder = border.target;
        break;

    }
    return <>{renderBorder}</>;
};

export { ColumnBorder }



const RacingStripe = (props: any) => {

  return (
  <div className="racing-stripe" style={props.style}></div>
)

};

export { RacingStripe }
