import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './index.scss';
// import { Match, navigate } from '@reach/router'
import { Router, Link, navigate, Match, Location, Redirect } from "@reach/router";
import { Dialog } from '@reach/dialog';
import create from 'zustand';
import 'fonts/DINPro-Regular.woff';
import List from '././screens/list/List';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import './theme/styles/styles.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { MoveWithForm } from 'screens/moveWithForm';
import { Thankyou } from 'screens/thankyou';
import { Login } from 'screens/login';
import { SignUp } from 'screens/signup';
import { PopupScreen } from 'screens/popUpScreen';
import { getEstimatorDetails } from 'core/services/movewithService'

import '@reach/dialog/styles.css';
import Home from 'screens/home/Home';
import KpiDetail from 'screens/kpiDetail/KpiDetail';
import { registerServiceWorker } from "./register-sw";
import { messaging } from "./init-fcm";
// We just use a store to augment the router navigation function so that it
// handles transition status and disable the body scroll during the transition
// (ie animation).

const [useStore] = create(set => ({
  status: 'idle',
  navigate: (address: any, domEl: any, state?: any) => {
    set({ status: 'transitioning' })
    if (domEl) disableBodyScroll(domEl)
    navigate(address, state)
  },
  endNav: () => {
    set({ status: 'idle' })
    clearAllBodyScrollLocks()
  }
}))

export { useStore }

export const isLogin = () => {
  if (localStorage.getItem("branchId")) {
    return true;
  }

  return false;
}

const ProtectedRoute = ({ component: Component, ...rest }: any) => (
  isLogin() ? <Component {...rest} /> : <Redirect from="" to="login" noThrow />
);

const PublicRoute = ({ component: Component, ...rest }: any) => (
  <Component {...rest} />
);


function Routes(props: any) {
  return (
    <Router {...props}>
      <PublicRoute path="/login" component={Login} />

      <ProtectedRoute path="/leaderboard" component={KpiDetail} />
      <ProtectedRoute path="/" component={Home} />

    </Router>
  );
}


//@ts-ignore
const App = ({ children: any }) => {

  const regPermission = async () => {
    if(messaging!=null){
      try {
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log("Token:" + token);
        navigator.serviceWorker.addEventListener("message", (message) => {
          console.log("message:" + JSON.stringify(message));
        });

        // Handle incoming messages. Called when:
        // - a message is received while the app has focus
        messaging.onMessage((payload: any) => {
          console.log('Message received. ', payload);

          navigator.serviceWorker.getRegistration().then(function (reg) {
            const notification = payload.notification;
            const data = payload.data;

            var options = {
              body: notification.body,
              vibrate: [100, 50, 100],
            }
            if (reg != null)
              reg.showNotification(notification.title, options);
          });
        });

        // navigator.serviceWorker.getRegistration().then(function(reg) {
        //   var options = {
        //     body: 'Here is a notification body!',
        //     icon: 'images/example.png',
        //     vibrate: [100, 50, 100],
        //     data: {
        //       dateOfArrival: Date.now(),
        //       primaryKey: 1
        //     },
        //     actions: [
        //       {action: 'explore', title: 'Explore this new world',
        //         icon: 'images/checkmark.png'},
        //       {action: 'close', title: 'Close notification',
        //         icon: 'images/xmark.png'},
        //     ]
        //   };
        //   if(reg)
        //     reg.showNotification('Hello world!', options);
        // });
      }
      catch (error) {
        console.log("error:" + error);
        const token = await messaging.getToken();
        if (token) {
          console.log("Token:"+token);
          return token;
        }
      }
    }
  }

if(messaging!=null){
    messaging.requestPermission()
        .then(() => {
          console.log('Notification permission granted.');
          regPermission();
        })
        .catch((err) => {
          if (err.code == "messaging/permission-blocked") {
            //Update the status at the backend accordingly
            alert('Unable to get permission to notify. Notifications have been blocked');
          }
          console.log('Unable to get permission to notify. ', JSON.stringify(err))
        })
  }



  const [count, setCount] = React.useState(0);
    return (
    <Location>
      {({ location, navigate }) => {
        //@ts-ignore
        const { oldLocation } = location.state || {};
        return (
          < >
            <Routes location={oldLocation != null ? oldLocation : location} />
            <Dialog
              style={{ zIndex: 9500 }}
              isOpen={oldLocation != null}
              onDismiss={() => {
                navigate(oldLocation.pathname);
              }}
            >
              <Routes location={location} />
            </Dialog>
          </>
        );
      }}
    </Location>
  )
};



registerServiceWorker();

const rootElement = document.getElementById('root')
//@ts-ignore
ReactDOM.render(<App />, rootElement)
