import {fetchGraphQL} from './graphqlService'

export async function validateUser(loginForm: any) {
    try {
        var params = `query{
            validateUser(UserID:"`+ loginForm.value.username + `",Password:"` + loginForm.value.password + `"){
                isValid
                employeeID
                employeeLoginID
                userType
                employeeName
                branchName
                branchID
            }
        }`;
        console.log(params);

        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
        localStorage.clear();
        if (response.data.validateUser[0].isValid === "1") {
            localStorage.setItem("branchName", response.data.validateUser[0].branchName)
            localStorage.setItem("employeeName", response.data.validateUser[0].employeeName)
            localStorage.setItem("branchId", response.data.validateUser[0].branchID)
            localStorage.setItem("userType",response.data.validateUser[0].userType)
            console.log(localStorage.getItem("branchName"))
            return response.data;

            return true;
        } 
        return false;
    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

}