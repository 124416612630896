import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Button as MaterialButton, CircularProgress, Typography } from '@material-ui/core';
import './Button.scss'

//@ts-ignore
const ColorButton: any = withStyles(theme => ({
  root: {
    color: '#ffffff',
    backgroundColor: '#00945D',
    borderColor: '#00945d;',
    boxShadow: 'none',
    textAlign: 'center',
    borderRadius: 100,
    // minHeight: 48,
    width: 'calc(100% - 60px)',
    maxWidth: '540px',
    borderWidth: 0,
    fontSize: "2rem",
    fontFamily: "'Heebo', sans-serif",
    textTransform: "uppercase",

    '&:hover': {
      backgroundColor: '#00613d',
      borderColor: 'none',
      cursor: 'pointer',
      color: '#ffffff',
      borderWidth: 0,
      textTransform: "uppercase",
      boxShadow: 'none',
    },
    '&:focus': {
      outline: 0,
      boxShadow: 'none',
    },


    rippleVisible: {
      opacity: 0.8
    },
  },

  "@keyframes mui-ripple-enter": {
    "0%": {
      transform: "scale(0)",
      opacity: 0.1
    },
    "100%": {
      transform: "scale(1)",
      opacity: 0.8
    }
  },
}))(MaterialButton);


export default function Button({ text, loading, disabled, width, height, fontSize, backgroundColor, fontWeight, textTransform, marginTop, paddingTop, formControl, onClick }: any) {


  const handleClick = () => {
    if (formControl) {
      formControl.setValue(true);
    }

    if (onClick) {
      onClick();
    }
  }

  return (
    <div className="comfort-btn">
      <ColorButton
        variant='contained'
        disabled={formControl ? !formControl._parent.valid : disabled}
        style={{ width: width, height: height, fontSize: fontSize, backgroundColor: backgroundColor, fontWeight: fontWeight, textTransform: textTransform, marginTop: marginTop, paddingTop: paddingTop }}
        onClick={() => handleClick()}
      >
        {/* {text} */}
        {loading ?
          <CircularProgress size={'1.5em'} style={{color: 'white'}} /> : // Size 14 works pretty well
          <Typography style={{color: 'white'}}>{text}</Typography>}
      </ColorButton>
    </div>
  );
}
