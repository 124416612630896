import React, { memo } from "react";
import { MoveWithCard, ImageCard } from "../card/templates";
import KpiCard from "../card/templates/KpiCard";
import { ImagePage } from "components/pageTemplates/imagePage";
import { MoveWithPage } from "components/pageTemplates/moveWithPage";
import GuageChart from 'components/guageChart/GuageChart';
import { ChartPage } from "components/pageTemplates/chartPage";
import { KPIPage } from "components/pageTemplates/KPIPage";
import { LeaderboardPage } from 'components/pageTemplates/leaderboardPage';
// import KpiTable from "components/KPI/KpiTable/KpiTable";

import { KpiProgressCard } from "components/KpiProgressCard";
import { Leaderboard } from "components/leaderboard";


const TemplateSelector = ({ ...props }) => {

    const selectTemplate = (key: any) => {
        let view: any;
        switch (key) {
            case 'imageCard':
                view = <ImageCard {...props} />
                break;
            case 'moveWithCard':
                view = <MoveWithCard {...props} />
                break;
            case 'imagePage':
                //@ts-ignore
                view = <ImagePage {...props} />
                break;
            case 'form':
                //@ts-ignore
                view = <MoveWithPage {...props} />
                break;
            case 'kpiSummaryCard':
            //@ts-ignore
                view = <KpiCard {...props} />
                break;
            case 'kpiProgressCard':
                //@ts-ignore
                view = <KpiProgressCard {...props}/>
                break;
            case 'kpiLeaderboardCard':
                //@ts-ignore
                view = <Leaderboard {...props}/>
                break;


            // TODO: Rename properly
            case 'opsCard':
                //@ts-ignore
                view = <KPIPage {...props} />
                break;
            case 'opsPage':
                //@ts-ignore
                view = <LeaderboardPage {...props} />
                break;
            case 'qualityCard':
                //@ts-ignore
                view = <KPIPage {...props} />
                break;
            case 'chartPage':
                //@ts-ignore
                view = <ChartPage {...props} />
                break;

        }
        return view;
    }

    return (
        selectTemplate(props['template'] || props['templateKey'])
    )
}

export default memo(TemplateSelector);
