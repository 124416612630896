import { IOption, Option } from "./option";
import { FormGroup, FormControl, FormArray, Validators } from "react-reactive-form";

export interface IForm {
    formPages: IFormPage[];
    visibleFormPages: IFormPage[]; // holds only one last invalid formPage maximum.
    currentFormPageIndex: number;
    currentFormPage: IFormPage;
    readonly progress: number;
    readonly isLastPage: boolean;
    formGroup: FormGroup;

    addPages(formPages: IFormPage[], params?: { clearControls?: boolean, parent?: string }): void;
    removePages(formPages: IFormPage[], params?: { clearControls?: boolean }): void;
    getVisiblFormPages(formPages?: IFormPage[]): IFormPage[];
    getIndex(key: string): number;
    destroy(): void;
    nextPage(): number;
}

export class FormModel implements IForm {
    formPages: IFormPage[];
    visibleFormPages: IFormPage[]; // holds only one last invalid formPage maximum.
    currentFormPage: IFormPage;
    formGroup: FormGroup;
    private _currentFormPageIndex: number;

    constructor(props: any, formGroup: FormGroup) {
        this.formGroup = formGroup;
        this._currentFormPageIndex = props.currentFormPageIndex || 0;
        this.formPages = props.formPages.map((page: any) => {
            page.form = this;
            return new FormPage(page);
        });

        this.addRemoveSubFormPages = this.addRemoveSubFormPages.bind(this);

        // Add subpages for pre-selected bubble field options.
        this.formPages.forEach((formPage, index) => {
            ([] as IField[]).concat(...formPage.fields).forEach(field => {
                // only if bubbleSelect field
                // if (field.options && field.options.find(item => item.selected)) {
                    // Add subpages for all selected options
                    let selectedOptions: IOption[] = field.getSelectedOptions();
                    if (selectedOptions.length > 0) {
                        // set current index after which to add pages. Do this before adding subpages for each option's FormPage.
                        this.currentFormPageIndex = index;
                        selectedOptions.forEach(selectedOption => {
                            this.addPages(selectedOption.subFormPages, { parent: formPage.key });
                        })
                    } 
                // }
            });
        });

        // Add subpages for pre-selected bubble field options.
        this.formPages.forEach((formPage, index) => {
            if (formPage.valid && formPage.subFormPages && formPage.subFormPages.length > 0) {
                formPage.subFormPages.forEach(subPage => {
                    this.addSubFormPages(subPage, index + 1);
                })
            }
        })

        // Add subpages for valid form pages

        this.visibleFormPages = this.getVisiblFormPages(this.formPages);
        this.currentFormPage = this.visibleFormPages[this.currentFormPageIndex];

        this.formGroup.valueChanges.subscribe(this.addRemoveSubFormPages);

        // reset index back to zero (as we changed it for adding pages in between)
        this.currentFormPageIndex = 0;
    }

    get currentFormPageIndex(): number {
        return this._currentFormPageIndex;
    }

    set currentFormPageIndex(index: number) {
        this._currentFormPageIndex = index;
        this.currentFormPage = this.formPages[index]; // TODO: Change it to visibleFormPages
    }

    get progress(): number {
        return (this.currentFormPageIndex + 1) / this.formPages.length;
    }

    // Return same index if no next page found.
    nextPage(): number {
        let nextIndex: number = this.currentFormPageIndex + 1;
        if (nextIndex <= this.getVisiblFormPages().length - 1) {
            this.currentFormPageIndex = nextIndex;
            return nextIndex;
        } else {
            return this.currentFormPageIndex;
        }
    }

    previousPage() {
    }

    // Add subapges at currentFormPageIndex
    // clearControls = true to clear formControls of subPages on their addition
    addPages(formPages: IFormPage[] = [], params: { clearControls?: boolean, parent?: string } = { clearControls: false }) {
        formPages.forEach((page, index) => {
            // if (!this.formPages.find(item => item === page)) {
            //     if (params.clearControls) {
            //         page.clearFormControls(); // clear formControl values before a subPage is added.
            //     }
            //     this.formPages.splice(this.currentFormPageIndex + (index + 1), 0, page);
                page.parent = params.parent;
                this.addPage(page, this.currentFormPageIndex + (index + 1), params);
            // }
        })
    }

    addPage(formPageToAdd: IFormPage, addAtIndex: number, params: { clearControls?: boolean } = { clearControls: false }) {
        if (!this.formPages.find(item => item === formPageToAdd)) {
            if (params.clearControls) {
                formPageToAdd.clearFormControls(); // Clear formControl values before a subPage is added.
            }
            this.formPages.splice(addAtIndex, 0, formPageToAdd);
        }
    }

    // clearControls = true to clear formControls of subPages on their removal
    removePages(formPages: IFormPage[] = [], params: { clearControls?: boolean } = { clearControls: false}) {
        formPages.forEach(formPage => {
            let index = this.formPages.findIndex(item => item === formPage);
            if (index >= 0) {
                this.formPages.splice(index, 1);
                if (params.clearControls) {
                    formPage.clearFormControls(); // clear formControl values after a subPage is remove.
                }
            }
        });
    }

    // To add subPages of subPage
    private addSubFormPages(formPage: IFormPage, addAtIndex: number) {
        this.addPage(formPage, addAtIndex);
        if (formPage.valid && formPage.subFormPages) {
            formPage.subFormPages.forEach((page, index) => {
                this.addSubFormPages(page, addAtIndex + (index + 1));
            });
        }
    }

    getVisiblFormPages(formPages: IFormPage[] = this.formPages): IFormPage[] {
        let validFormPages: IFormPage[] = [];
        for (let i = 0; i < formPages.length; i++) {
            // if (formPages[i].valid || formPages[i].optional) {
                validFormPages.push(formPages[i]);
            // } else {
                // validFormPages.push(formPages[i]);
                // break;
            // }
        }

        return validFormPages;
    }

    getIndex(key: string): number {
        let index = this.formPages.findIndex(item => item.key === key);
        return index;
    }

    get isLastPage(): boolean {
        return this.currentFormPageIndex === (this.formPages.length - 1);
    }

    destroy() {
        this.formGroup.valueChanges.unsubscribe(this.addRemoveSubFormPages);
    }

    private addRemoveSubFormPages(value: any) {
        this._addRemoveSubFormPages();
    }

    private _addRemoveSubFormPages(formPage: IFormPage = this.currentFormPage, index: number = this.currentFormPageIndex) {
        if (formPage.subFormPages && formPage.subFormPages.length > 0) {
            if (formPage.valid) {
                formPage.subFormPages.forEach((item, index) => {
                    this.addPage(item, this.currentFormPageIndex + (index + 1), { clearControls: true });
                })
            } else {
                this.removePages(formPage.subFormPages, {clearControls: true});
            }
        }
    }
}

export interface IFormPage {
    key: string;
    title: string;
    shortTitle?: string;
    subTitle?: string;
    readonly valid: boolean;
    progressTab: IProgressTab;
    optional?: boolean;
    fields: IField[] | IField[][];
    subFormPages: IFormPage[];
    parent?: string;
    readonly hasChild: boolean;
    nextButton?: string;
    goNextOnValid?: boolean;
    footer?: boolean;
    
    form: IForm;
    formGroupName?: string;
    formArrayName?: string;
    formGroup?: FormGroup;
    formArray?: FormArray;
    formArrayIndex?: number;

    // TODO move this to separate ui:{} object in JSON representation of form
    // UI related
    progressTabColor?: string;
    headerHeight?: string;

    clearFormControls(): void;
}


// A FormPage is either added as dynamic FromGroup to FormArray OR has its fromGroup (root or nested) defined statically by formGroupName
// So only either formArrayIndex of formGroupName is specified at FormPage level.
// If none is specified then root formGroup is used
export class FormPage implements IFormPage {
    key: string;
    title: string;
    shortTitle?: string;
    subTitle?: string;
    progressTab: IProgressTab;
    optional?: boolean;
    fields: IField[] | IField[][] = [];
    subFormPages: IFormPage[];
    parent?: string; // parent of this formPage if any
    nextButton?: string;
    goNextOnValid?: boolean;
    footer?: boolean;

    form: IForm;
    formGroupName?: string;
    formArrayName?: string;
    formGroup?: FormGroup;
    formArray?: FormArray;
    formArrayIndex?: number;

    // UI related. TODO: move to separte ui object
    progressTabColor?: string;
    headerHeight?: string;

    constructor(props: IFormPage) {
        this.key = props.key || '';
        this.title = props.title || '';
        this.shortTitle = props.shortTitle;
        this.subTitle = props.subTitle;
        this.optional = props.optional;
        this.nextButton = props.nextButton;
        this.goNextOnValid = props.goNextOnValid;
        this.footer = props.footer;
        this.progressTab = new ProgressTab({ label: this.subTitle || this.title });

        // UI related. TODO: move to separte ui object
        this.progressTabColor = props.progressTabColor;
        this.headerHeight = props.headerHeight;

        this.form = props.form;

        this.subFormPages = (props.subFormPages || []).map(item => {
            item.form = this.form;
            return new FormPage(item)
        })

        //@ts-ignore
        this.formArrayIndex =  props.formArrayIndex >= 0 ? props.formArrayIndex : -1;

        // points to root form group if none is defined.
        if (props.formArrayName && props.formGroupName) {
            throw new Error("Cannot define both formArray and formGroup on same formPage");
        }

        // formArrayIndex means dynamically create FormGroup for this formPage.
        // formGroupName means static formGroup (already defined).
        // Only use one of these.
        //@ts-ignore
        if (this.formArrayIndex >= 0 && props.formGroupName) {
            throw new Error("Cannot define both formArrayIndex and formGroupName on same formPage");
        }

        // Cannot define `formArrayIndex` without specifying formArrayName at page level
        //@ts-ignore
        if (this.formArrayIndex >= 0 && !props.formArrayName) {
            throw new Error("Can define formArrayIndex only if formArrayName is defined on same FormPage");
        }

        // If defined, this formPage/fields belong to formArray
        if (props.formArrayName) {

            // get formArray instance
            this.formArrayName = props.formArrayName;
            this.formArray = this.form.formGroup.get(this.formArrayName) as FormArray;

            if (!this.formArray) {
                throw new Error("FormArray not found");
            }
            
            // Add SubPage formGroup in FormArray at specified index, if specified
            // formArrayIndex means dynamic formGroup (with no name)
            //@ts-ignore
            if (this.formArrayIndex >= 0) {
                this.formGroup = new FormGroup({});
                // this.formArray.insert(this.formArrayIndex, this.formGroup);
                // Always push the control/formGroup and update the formArrayIndex to new one now
                this.formArray.push(this.formGroup);
                this.formArrayIndex = this.formArray.length - 1; // 
            }

        } else if (props.formGroupName) {
            this.formGroupName = props.formGroupName;
            this.formGroup = this.form.formGroup.get(this.formGroupName) as FormGroup;
        }

        //@ts-ignore
        this.fields = props.fields.map(field => {
            if (Array.isArray(field)) {
                return field.map(field => {
                    field.form = props.form;
                    field.formPage = this;
                    return new Field(field)
                })
            } else {
                field.form = props.form;
                field.formPage = this;
                return new Field(field)
            }
        });

    }

    get valid(): boolean {
        return !([] as IField[]).concat(...this.fields).find(item => !item.valid && !!item.required);
    }

    // Clear all formControls of all fields of formPage. Needed when we need to clear a subPage on its addition/removal.
    clearFormControls(): void {
        ([] as IField[]).concat(...this.fields).forEach(field => field.clearFormControls());
    }

    get nextPage(): IFormPage {
        return this.form.getVisiblFormPages()[this.form.currentFormPageIndex + 1];
    }

    get hasChild(): boolean {
        return !!this.nextPage && !!this.nextPage.parent;
    }

    // This form page is incomplete if it has child pages OR it is child AND not last child.
    get incompleteForm () {
        return this.hasChild || !!this.parent
    }
}


export interface IField {
    id?: string;
    label: string;
    fieldType: string;
    formControlName: string;
    formArrayIndex: number;
    bindLabel: string;
    bindValue: string;
    keyboardType: string;
    returnKeyType: string;
    autoFocus?: boolean;
    placeholder?: string;
    required?: boolean;
    order?: number;
    value: any; // field value used in formControl
    options?: IOption[];
    selected?: IOption | IOption[]; // Selected option(s). single & multiple select
    mask: any;
    readonly valid: boolean;

    limit: number; // TODO: move this to select component specific class only
    
    width?: any; //styling of theme component
    marginLeft?: string;
    marginRight?: string;
    form: IForm;
    formPage: IFormPage; // formPage to which this field belongs
    formControl?: FormControl;

    getSelectedOptions(): IOption[];
    clearFormControls(): void;
}

// A field is either added as dynamic formControl to FormArray OR added to formGroup as formControlName
// depending upon either formArrayIndex or formControlName is defined for field.
export class Field implements IField {
    id?: string;
    label: string;
    fieldType: string;
    formControlName: string;
    formArrayIndex: number;
    bindLabel: string;
    bindValue: string;
    keyboardType: string;
    returnKeyType: string;
    autoFocus?: boolean;
    placeholder?: string;
    required?: boolean;
    order?: number;
    value: any; // field value used in formControl
    mask: any;
    options?: IOption[];
    selected?: IOption | IOption[]; // Selected option(s). single & multiple select
    limit: number; // TODO: move this to select component specific class only
    width: any;
    marginLeft: any;
    marginRight: any;
    form: IForm;
    formPage: IFormPage; // formPage to which this field belongs
    formControl?: FormControl;

    constructor(props: IField) {
        this.id = props.id;
        this.label = props.label;
        this.fieldType = props.fieldType;
        this.formControlName = props.formControlName;
        this.bindLabel = props.bindLabel;
        this.bindValue = props.bindValue;
        this.formControlName = props.formControlName; // when part of formGroup
        this.formArrayIndex = props.formArrayIndex; // when part of formArray
        this.keyboardType = props.keyboardType;
        this.returnKeyType = props.returnKeyType;
        this.autoFocus = props.autoFocus;
        this.placeholder = props.placeholder;
        this.required = props.required;
        this.mask = props.mask;
        this.order = props.order;
        this.limit = props.limit; // TODO: move this to select component specific class only
        this.width = props.width; 
        this.marginLeft = props.marginLeft; 
        this.marginRight = props.marginRight; 

        this.form = props.form;
        this.formPage = props.formPage;

        if (props.options) {
            this.options = props.options.map(option => {
                return this.newOption(option)
            });
        }

        this.value = props.value;


        // TODO Refactor this code to its own Select field model.
        if (this.fieldType === "singleSelect" || this.fieldType === "multiSelect") {

            if (this.fieldType === "multiSelect" && this.value) {
                if (!Array.isArray(this.value)) {
                    throw new Error("Field.value must be an array for multiselect field");
                }
                
                // Find existing option from options for each value in array
                this.selected = this.value.map((item: any) => this.findOption(item)) as IOption[];
            } 
            else if (this.value) { // if object
    
                // pick selected instance from list of options instead of using a new instance
                this.selected = this.findOption(this.value) as IOption;
            }
        }

        // formControlName is specified when field is part of FormGroup
        // If field is part of FormArray instead, then instead of formControlName, formArrayIndex is specified
        if (this.formControlName && !this.formPage.formGroup && !this.form.formGroup) {
            throw new Error("Cannot define formControlName without formGroup");
        }

        if (this.formArrayIndex && !this.formPage.formArray) {
            throw new Error("Cannot specify formArrayIndex without specifying formArray");
        }

        if (this.formArrayIndex && this.formControlName) {
            throw new Error("Cannot specify both formControlName && formArrayIndex");
        }

        // formControlName specified means this field is part of FormGroup, not formArray
        if (this.formControlName) {
            // Use root formGroup if not defined at formPage level.
            let formGroup: FormGroup = this.formPage.formGroup || this.form.formGroup;

            this.formControl = formGroup.get(this.formControlName) as FormControl;

            
            // Create one if one doesn't exist already
            if (!this.formControl) {
                let validator = null;
                if (this.required ) {
                    validator = Validators.required;
                } else if (this.keyboardType === 'email') {
                    validator = Validators.email;
                }
                this.formControl = new FormControl('', validator);
                formGroup.addControl(this.formControlName, this.formControl);
            }

            if (this.value) {
                this.formControl.setValue(this.value);
            }
        }
        // If formArrayIndex is specifed for this field, it means this field will be added (dynamically) as FormControl to parent formArray.
        else if (this.formArrayIndex >= 0 && this.formPage.formArray) {

            // this.formControl = this.formPage.formArray.at(this.formArrayIndex) as FormControl;
            
            // Create new formControl for each field if none exists at the index (for example, for dynamically added fields not known at start time)
            if (!this.formControl) {

                this.formControl = new FormControl(this.value, this.required ? Validators.required : null);
                // this.formPage.formArray.insert(this.formArrayIndex, this.formControl);
                
                // Always push the control/formGroup (end of array) and update the formArrayIndex to new one now.
                this.formPage.formArray.push(this.formControl);
                this.formArrayIndex = this.formPage.formArray.length - 1;
            }
        }
    }

    get valid(): boolean {
        return this.formControl ? this.formControl.valid : false;
        // return true;
        // return !!(this.options || []).find(item => item.selected);
    }

    // TODO - Move to dropdown subfield model when we break down Field into respective sub-type fields
    getSelectedOptions(): IOption[] {
        let selectedOptions: IOption[] = [];
        if (this.options && this.formControl) {
            // Move this to respective sub-controls, multi-select, select etc.
            if (this.fieldType === 'multiBubbleSelect') {
                //@ts-ignore
                selectedOptions = this.options.filter(option => this.formControl.value.indexOf(option.bindValue) >= 0);
            } else {
                //@ts-ignore
                selectedOptions = this.options.filter(option => this.formControl.value === option.bindValue);
            }
        }
        return selectedOptions;
    }

    // Clear formControls of the field. Needed because when we add a form subPages we clear its previous selected user values.
    clearFormControls(): void {
        this.formControl && this.formControl.setValue('', { emitEvent: false });
    }

    // Find option from list of options that matches given value.
    private findOption(value: any): IOption {
        if (Array.isArray(value)) {
            throw new Error("Cannot pass array to Field.findOption() function");
        }

        let matchValue: any;
        
        if (typeof value === 'object') {
            // TODO: if bindValue is not defined, we use bindLabel (instead of whole object comparison) to match selectedOption to options
            matchValue = this.bindValue ? value[this.bindValue] : value[this.bindLabel];
        } else { // primitive value
            matchValue = value;
        }

        return (this.options || []).find(item => 
            item.bindValue === matchValue || item.bindLabel == matchValue) as IOption;
    }

    private newOption(option: any): IOption {
        option.form = this.form;
        option.field = this;
        return new Option(option)
    }
}

export interface IProgressTab {
    label: string;
    isActive?: boolean;
    isValid?: boolean;
    width?: number; // width in pixels
}

export class ProgressTab {
    label: string;
    isActive?: boolean;
    isValid?: boolean;
    width?: number;

    constructor(props: IProgressTab) {
        this.label = props.label;
        this.isActive = props.isActive || false;
        this.isValid = props.isValid || false;
    }
}