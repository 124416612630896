import {fetchGraphQL} from 'core/services/graphqlService'

export async function opsKpidata(branchId: any) {
 
    try {
        var params = `query{
            opsKpiSummary(BranchID:"`+branchId+`"){
             row
             label
             yoy
             target
             currentYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             lastYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             leaderBoard{
               name
               color
               code
               score
             }
           }
           }`;
        // console.log(params);

        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
        // console.log(response.data.opsKpiSummary);
        // localStorage.setItem("opskpi", JSON.stringify(response.data.opsKpiSummary));
        // return JSON.stringify(response.data.opsKpiSummary);
        return response.data.opsKpiSummary;
        // setTimeout(() => {
        //     //  endNav()
        // }, 400);

    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

}

export async function qualityKpidata(branchId: any) {
  
 //alert(branchId);
    try {
        var params = `query{
            qualityKpiSummary(BranchID:"`+branchId+`"){
             row
             label
             yoy
             target
             currentYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             lastYear
             {
               currentAmount
               totalAmount
               remaining
               goal
               progressPercent
             }
             leaderBoard{
               name
               color
               code
               score
             }
           }
           }`;
         console.log(params);

        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
        // console.log(response.data.qualityKpiSummary);
        // localStorage.setItem("qualityKpi", JSON.stringify(response.data.qualityKpiSummary));
        return response.data.qualityKpiSummary;

    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

}