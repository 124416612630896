import React, { Component } from 'react'
//import { Redirect } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
// import ListGroup from 'react-bootstrap/ListGroup';
import moment from "moment";
import { FormControl } from 'react-reactive-form';
 import './DatePicker.scss';
 import ListGroup from 'react-bootstrap/ListGroup';
 
export interface DatePickerProps {
    formControl?: FormControl
}

export interface DatePickerState {
    date: Date;
    selectedDay: any;
}


class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props: any) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.Navbar = this.Navbar.bind(this);
        this.state = {
            selectedDay: new Date(this.props.formControl ? new Date(this.props.formControl.value) : new Date()),
            date: new Date(),
        };
    }

    Navbar() {
        return (
            <></>
        );
    };



    handleDayClick(day: any) {

        // var selected = moment(day.toLocaleDateString()).format("MM/DD/YYYY");
        let today = new Date();
        today.setHours(0,0,0,0)
        if (new Date(day) >= today) {
            localStorage.setItem("movedate", new Date().toISOString());
            if (this.props.formControl) {
                this.props.formControl.setValue(new Date(day).toISOString());
                this.props.formControl.markAsDirty();
                this.props.formControl.markAsTouched();
            }
            this.setState({ selectedDay: new Date(day) })
        }

    };
    
    render() {


        return (
            <div>
                <Container fluid className="force-shrink">
                    <Row>
                        {/* <Col sm>
                            <div className="move-header"><h4>{this.state.header.title}</h4>{this.state.header.subtitle ? <p>{this.state.header.subtitle}</p> : null}</div>
                        </Col> */}
                    </Row>
                    <Row className="force-shrunk">

                        <Col>
                            {/* //@ts-ignore */}
                            <DayPicker 
                                fromMonth={this.state.date} 
                                numberOfMonths={4}
                                selectedDays={this.state.selectedDay}
                                onDayClick={this.handleDayClick}
                                disabledDays={{ before: this.state.date }} 
                                navbarElement={<this.Navbar />} 
                            />
                        </Col>

                    </Row>


                    {/* <Row className="force-shrunk">

                        <Col>

                            {<ListGroup variant="flush">
                                {this.state.dates.map((MoveData, index) => (
                                    <ListGroup.Item action  as="button" className="list-group-notsure" key={MoveData.key}>
                                        <div className="txt"> {MoveData.title} {MoveData.date} </div>
                                      
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>}

                        </Col>
                    </Row> */}
                </Container>
            </div>
        )

    }
}

export default DatePicker;
