import { ICard } from 'shared/model';


export const getKpiCards = (opsKpi: any, qualityKpi: any): any[] => {
    return [
        {
            "title": `Operational KPIs`,
            "template": "kpiSummaryCard",
            "data": {
                "title": "OPs",
                "kpis": opsKpi
            },
            "page": {
                "template": "opsPage",
                "sharedElement": {
                    "height": 900
                },
            },
            "width": {
                "mobile": "full",
                "tablet": "full",
                "desktop": "half"
            },
            "heightMultiplier": {
                "mobile": 0.8,
                "tablet": 0.4,
                "desktop": 0.5
            }
        },
        {
            "title": `Quality KPIs`,
            "template": "kpiSummaryCard",
            "data": {
                "title": "Quality",
                "kpis": qualityKpi
            },
            "page": {
                "template": "KpiDetails",
                "widthMultiplier": 1,
                "sharedElement": {
                    "height": 500
                },
            },
            "width": {
                "mobile": "full",
                "tablet": "full",
                "desktop": "half"
            },
            "heightMultiplier": {
                "mobile": 0.8,
                "tablet": 0.4,
                "desktop": 0.5
            }
        }
    ];
}
