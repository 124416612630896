export async function fetchGraphQL(method: string, params: string) {
    var jsonData = null;
    //var endPoint = 'http://cms2grpahqlapi-prod.us-east-1.elasticbeanstalk.com/graphql';
    //http://cms2grpahqlapi-prod.us-east-1.elasticbeanstalk.com/graphql/
    var endPoint = 'http://cms2grpahqlapi-prod.us-east-1.elasticbeanstalk.com/graphql';
    try {
        const fetchResult = fetch(endPoint, {
            method: method.toLocaleUpperCase(),
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query: params }),
        });

        const response = await fetchResult;
        console.log("response:" + JSON.stringify(response));

        if (response.ok) {
            jsonData = await response.json();
            console.log(jsonData);
        } else {
            jsonData = null;
            throw new Error(response.statusText);
        }
        return jsonData;
    }
    catch (err) {
        //network related errors
        console.error("fetchGraphQL:" + err);
        throw err;
    }
}