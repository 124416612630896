import React from "react";
import { FiPhone } from "react-icons/fi";
//import logo from "../../assets/images/AMS_Logo2.png";
// import { logo } from 'assets/images';
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';
import './SubHeader.scss';
import { ProgressBar } from "components/progressBar";
import Nav from "screens/nav/nav";
import { IoMdArrowDropleft } from "react-icons/io";


export interface SubHeaderProps {
    backIcon?: boolean;
    progress?: number;
    // leftIcon?: any;
    leftIcon?: string;
    leftText?: string;
    rightIcon?: string;
    rightText?: string;
    onBackPress?(): void;
    onLeftIconPress?(): void;
    onRightIconPress?(): void;
    onLogoPress?(): void;
}

export interface SubHeaderState {
    phone: any
}

class SubHeader extends React.Component<SubHeaderProps, SubHeaderState> {
    constructor(props: SubHeaderProps) {
        super(props);
        var phoneNumber = localStorage.getItem("phone") == null ? "8442070741" : localStorage.getItem("phone");
        phoneNumber = "tel:" + phoneNumber;
        this.state = {
            phone: phoneNumber
        };
    }

    handleClick() {
        if (this.props.onBackPress) {
            this.props.onBackPress();
        }
    }
    onLeftIconClick() {
        if (this.props.onLeftIconPress) {
            this.props.onLeftIconPress();
        }
    }
    onRightIconClick() {
        if (this.props.onRightIconPress) {
            this.props.onRightIconPress();
        }
    }
    onLogoClick() {
        if (this.props.onLogoPress) {
            this.props.onLogoPress();
        }
    }


    render() {
        return (
            <header id="opscard-header">
                <div className="header-content">
                    {!this.props.backIcon ?
                        <div className="header-link price" role="link" onClick={() => this.onLeftIconClick()}>
                                <IoMdArrowDropleft name={`${this.props.leftIcon} link-icon`} />
                                <span className="link-label">{this.props.leftText}</span>
                        </div>
                        :
                        <div className="header-link back" role="link" onClick={() => this.handleClick()}>
                                <IoMdArrowDropleft name={`${this.props.leftIcon} link-icon`} />
                        </div>
                    }

                    {/* <div className="header-logo" role="link" onClick={() => this.onLogoClick()}>
                        <img src={logo} className="" alt="logo" />
                    </div> */}

                    {!this.props.onRightIconPress ?
                    <div className="left-link">
                      <div className="header-link call">
                        <SimpleLineIcon size={'2em'} name={`${this.props.rightIcon} link-icon`} />
                        <span className="link-label"> {this.props.rightText}</span>
                      </div>
                      </div>
                       :
                       <div className="header-link" role="link" onClick={() => this.onRightIconClick()}>
                        <SimpleLineIcon name={this.props.rightIcon} />
                        <span className="link-label"> {this.props.rightText}</span>
                    </div>               
                    }

                </div>


                {this.props.progress &&
                    <div className="progress-container">
                        <ProgressBar progress={this.props.progress} />
                    </div>
                }
            </header>
        );
    }
}

export default SubHeader;
