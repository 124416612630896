import React from 'react'
import TableIcon from "components/KPI/TableIcons/TableIcons";
import { ColumnBorder, RacingStripe } from "components/KPI/KpiTable/TableDecorations";
import "./KpiProgressCard.scss";
import { KpiProgressBar } from 'components/KpiProgressBar';

import { LeaderboardOriginal } from 'components/KPI/Leaderboard';

export interface KpiProgressCardProps {
    data?: any;
    title?: any;
    height?: any;
    remainingwidth?: any;
    activewidth?: any;
    style?: object;
}

export interface KpiProgressCardState {
    lastYearWidth?: number;
    thisYearWidth?: number;
}

class KpiProgressCard extends React.Component<KpiProgressCardProps, KpiProgressCardState> {
    public static defaultProps = {
        data: { title: "" }
    };
    constructor(props: KpiProgressCardProps) {
        super(props);
        this.state = {
            thisYearWidth: 100,
            lastYearWidth: 100,
        };
    }


    componentDidMount = () => {
        console.log(this.props.data);
        let lastYearGoal: number = this.props.data.kpi.lastYear[0].goal;
        let thisYearGoal: number = this.props.data.kpi.currentYear[0].goal;
        let lastYearWidth: number;

        if (lastYearGoal > 0 && thisYearGoal > 0) {
            lastYearWidth = lastYearGoal * 100 / thisYearGoal;
            this.setState({ lastYearWidth: lastYearWidth });

        }
    }

    randomTestWidth = () => {
        //this is just for testing the UI when data is unavailable
        let randomLastYear: number = Math.random() * (74000 - 55000) + 55000;
        let randomThisYear: number = Math.random() * (90000 - 75000) + 75000;
        const randomGoalWidthNumber = randomLastYear * 100 / randomThisYear;
        return randomGoalWidthNumber;
    }

    formatAmount(num: number): number | string {
        //@ts-ignore
        return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K' : Math.sign(num)*Math.abs(num)
    }

    render() {
        const performanceColor = {
            up: "#8FC113",
            neutral: "#666666",
            down: "#F25F4A"
        };

        const arrowDirection = {
            up: "up",
            neutral: "neutral",
            down: "down",
        };

        return (
            <div id="progress-card-container">

                <ProgressHeader
                    title={this.props.data.kpi.label}

                    percentYOY={`${this.props.data.kpi.yoy}%`}
                    yoyColor={this.props.data.kpi.yoy >= 0 ? performanceColor.up : performanceColor.down}

                    percentTarget={`${this.props.data.kpi.target}%`}
                    targetColor={this.props.data.kpi.target >= 0 ? performanceColor.up : performanceColor.down}
                />

                <KpiProgressBar
                    progress={this.props.data.kpi.currentYear[0].progressPercent}
                    activecolor={'#1FB3E4'}
                    remainingcolor={'#32474E'}
                    leftlabel={this.formatAmount(this.props.data.kpi.currentYear[0].currentAmount)}
                    rightlabel={this.formatAmount(this.props.data.kpi.currentYear[0].remaining)}
                    className={'thisyear'}
                    labelbgcolor={'#3D6977'}
                    goal={this.props.data.kpi.currentYear[0].goal}
                    goalWidth={`${this.state.thisYearWidth}%`}
                />

                <KpiProgressBar
                    progress={this.props.data.kpi.lastYear[0].progressPercent}
                    activecolor={'#BBBBBB'}
                    remainingcolor={'#434343'}
                    leftlabel={this.formatAmount(this.props.data.kpi.lastYear[0].currentAmount)}
                    rightlabel={this.formatAmount(this.props.data.kpi.lastYear[0].remaining)}
                    className={'lastyear'}
                    labelbgcolor={'#727272'}
                    goal={this.props.data.kpi.lastYear[0].goal}
                    goalWidth={`${this.state.lastYearWidth}%`}
                />

            </div>
        );
    }
}

export default KpiProgressCard;


const ProgressHeader = (props: any) => {

    const performanceColor = {
        up: "#8FC113",
        neutral: "#666666",
        down: "#F25F4A"
    };

    let headerColor;
    let stripeColor;

    if (props.yoyColor && props.targetColor === performanceColor.up) {
        headerColor = performanceColor.up;
        stripeColor = performanceColor.up;
    } else {
        headerColor = "#ffffff";
        stripeColor = "#666666";
    }

    var racingStripe = {
        boxShadow: "inset -5px 5px 1px" + stripeColor,
    };

    return (
        <>
            <RacingStripe style={racingStripe} />
            <div className="progress-header">
                <h2 style={{ color: headerColor }}>{props.title}</h2>
                <div className="progress-header_summary" style={{ color: props.yoyColor, borderColor: props.yoyColor }}>
                    <TableIcon icon="yoy" color={props.yoyColor} />
                    <TableIcon icon={props.yoyColor == performanceColor.up ? "up" : "down"} />
                    <span>{props.percentYOY}</span>
                </div>
                <div className="progress-header_summary" style={{ color: props.targetColor, borderColor: props.targetColor }}>
                    <TableIcon icon="target" color={props.targetColor} />
                    <TableIcon icon={props.targetColor == performanceColor.up ? "up" : "down"} />
                    <span>{props.percentTarget}</span>
                </div>
            </div>
        </>
    )
};
