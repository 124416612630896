import React, { Component, useState, useEffect, useCallback } from 'react'
import List from 'screens/list/List';
import { getCard } from './kpiDetailData';
import { SubHeader } from 'components/subHeader';
import { navigate } from '@reach/router';
import moment from 'moment';

export interface KpiDetailProps {
    path?: string;
}

export interface KpiDetailState {
}

const KpiDetail = ({ path, ...props }: KpiDetailProps) => {

    //@ts-ignore
    let cardData = props.location.state.card.data;
    let kpiDetailCards: any = cardData.kpis.map((kpi: any) => getCard(kpi));
    kpiDetailCards = kpiDetailCards.flat();

    const handleCardClick = useCallback(
        i => {
            // alert("handle card click")
        }, [])

    function onLeftIconPress() {
        navigate('/');
    }
    return (
        <>
            <SubHeader
                progress={undefined}
                leftIcon={'arrow-left'}
                leftText={'Back'}
                // rightIcon={'user'}
                rightText={moment().format("MMMM")}
                onLeftIconPress={onLeftIconPress}
            // onRightIconPress={onRightIconPress}
            />
            <List cards={kpiDetailCards} onCardClick={handleCardClick} />
        </>
    )
}

export default KpiDetail;
