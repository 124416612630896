import {fetchGraphQL} from './graphqlService'

export async function getBranches(branchId: any) {
    try {
        var params = `query{
            branchData(BranchID:"`+ branchId + `")
            {
                value
                label
              }
        }`;
        console.log(params);

        //validateUser(UserID:"jdas",Password:"ams2018!"){
        var response = await fetchGraphQL('POST', params);
        console.log(response.data);
        localStorage.setItem("CouponDates", JSON.stringify(response.data));
        return response.data.branchData;
    } catch (err) {
        //network related errors
        console.error("err:" + JSON.stringify(err));
    }

}