import * as firebase from "firebase/app";
import "firebase/messaging";

let messagingObj=null;
if (firebase.messaging.isSupported()) {
  const initializedFirebaseApp = firebase.initializeApp({
      apiKey: "AIzaSyDliNQjL2KtDuwSSrL357n9J4Ld_3hR9NM",
      authDomain: "cms2-de69d.firebaseapp.com",
      databaseURL: "https://cms2-de69d.firebaseio.com",
      projectId: "cms2-de69d",
      storageBucket: "cms2-de69d.appspot.com",
      messagingSenderId: "975693663130",
      appId: "1:975693663130:web:033ab31cdd3e434ce74823",
      measurementId: "G-G5DCCE485M"
  });

  messagingObj = initializedFirebaseApp.messaging();

  messagingObj.usePublicVapidKey(
    "BFoUexH5jq7XMWtcHoKVeEcPbBfeo2fLeLu2I_ZwP0bNhetd2in97OiyYCpo6TBp9SvcPMk7WedARCuMOzb9Wz0"
  );
}
else{
  // alert("This browser doesn't support the API's required to use the firebase SDK");
  alert("This browser doesn't support the API's required for Push Notification");
}

const messaging=messagingObj;

export { messaging };