import React from "react";
import "./Image.scss";

export interface MoveImageProps {
    className?: string;
    src?: string;
    width?: any;
    height?: number;
    style?: object;
}
 
export interface MoveImageState {
    
}
 
class MoveImage extends React.Component<MoveImageProps, MoveImageState> {
    constructor(props: MoveImageProps) {
        super(props);
        this.state = {  };
    }

    render() { 
        return ( 
            <div className="img-container">
            <img  alt="" className={`imgstyle ${this.props.className}`} src={this.props.src} style={{ width: this.props.width, height: this.props.height, ...this.props.style }} />
        </div>
         );
    }
}
 
export default MoveImage;