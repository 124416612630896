import React, { forwardRef } from "react";

const ImageCard = ({ data }: { data?: any } = { data: {} }, ref: any) => {
    

    return (
    <div className="title-text">
        <h3>{data ? data.category : 'Default Category'}</h3>
        <h2>{data ? data.title : 'Default Title'}</h2>
    </div>
    )
  }
  
  export default forwardRef(ImageCard)