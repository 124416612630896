import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react'
import { TransitionGroup } from 'react-transition-group'
import { useStore } from '../../index'
import Card from 'components/card/Card'
import Page from 'components/page/Page'
import { cardListJson } from './data';
import { cardListJson2 } from './data2';
//@ts-ignore
import AutoResponsive from "autoresponsive-react";
import {
  isTablet,
  isBrowser,
  isMobile
} from "react-device-detect";

import './List.css'
import { useDrag } from 'react-use-gesture';
import { useSpring, a } from 'react-spring';
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';
// import { Header } from 'components/header';
import { ICard } from 'shared';

const ITEM_MARGIN = 20;

export interface ListProps {
  cards: ICard[];
  index?: number;
  path?: string;
  onCardClick?(event: any): void;
}


export default function List({ index, path, cards, onCardClick }: ListProps) {
  if (index) {
    index = ~~index;
  } else {
    index = -1;
  }
  const navStatus = useStore((state: any) => state.status)
  const navigate = useStore((state: any) => state.navigate)
  const endNav = useStore((state: any) => state.endNav)

  // The ref of the list node
  const mainRef = useRef(null)

  // A ref to the scroll position, we will need to store it before freezing the
  // list node (ie position: fixed, overflow: none)
  const scroll = useRef(0)

  // cardNodes will hold all the node refs of the cards
  const [cardNodes] = useState(() => new Map());
  const [cardData, setCardData] = useState(() => cards || []);
  const [layout, setLayout] = useState(0);
  // console.dir(cardData);
  const [containerWidth, setContainerWidth] = useState(375);
  const [screenDivider, setScreenDivider] = useState(2);
  const [device, setDevice] = useState(getDevice());


  // List status is idle when no page is opened (ie index === 1) and the
  // navigation status is also idle.
  const idle = index === -1 && navStatus === 'idle'

  // Function called by all refs from the Card components: it will add all the
  // card refs to cardNodes so that we can retrieve the opened card.
  const addCardNode = useCallback((node, i) => node && cardNodes.set(i, node), [cardNodes])

  // Returns the card matching the index passed as a parameter.
  const getOpenedCard = useCallback(i => {
    let card = cardNodes.get(i)
    return card;
  },
    [cardNodes]
  )

  // onClick handler that navigates to the page index
  const handleCardClick = useCallback(
    i => {
      // We store the current window scrollY so that we can freeze the list
      // container later.
      scroll.current = window.scrollY
      // if (i === 0) {
      //   navigate('/move', mainRef.current)

      // } else {
      if (onCardClick) {
        onCardClick(cardData[i])
      } else {
        navigate(i, mainRef.current)
      }
      // }
    },
    [navigate]
  )

  // This will take care of freezing the list container when the transition to
  // the page starts, and unfreezing it when the exit transition finishes.
  useEffect(() => {
    if (idle) {
      //@ts-ignore
      mainRef.current.classList.remove('frozen')
      //@ts-ignore
      mainRef.current.style.width = ''
      window.scrollTo(0, scroll.current)
    } else {
      //@ts-ignore
      mainRef.current.style.width = mainRef.current.offsetWidth + 'px'
      //@ts-ignore
      mainRef.current.classList.add('frozen')
      //@ts-ignore
      mainRef.current.scrollTop = scroll.current
      window.scrollTo(0, 0)
    }
  }, [idle])

  const onRefresh = () => {
    window.location.reload();
  }

  const onClick = () => {
    navigate('/login', mainRef.current)
  }

  useLayoutEffect(() => {
    if (mainRef.current) {
      //@ts-ignore
      setContainerWidth(mainRef.current.clientWidth)
    }
    window.addEventListener('resize', () => {
      setDevice(getDevice());
      //@ts-ignore
      // let clientWidth = document.body.clientWidth;
      // if (clientWidth <= 600) {
      //   setDevice("mobile");
      // } else if (clientWidth <= 1100) {
      //   setDevice("tablet");
      // } else {
      //   setDevice("desktop");
      // }
      // debugger;
      //@ts-ignore
      mainRef.current && setContainerWidth(mainRef.current.clientWidth);
    }, false);
  }, [mainRef.current])


  const onLayoutSwitch = useCallback(() => {
    // debugger;
    if (layout === 0) {
      setLayout(1);
      //@ts-ignore
      setCardData(cardListJson2);
    } else {
      setLayout(0);
      //@ts-ignore
      setCardData(cardListJson);
    }
  },
    [layout]
  )

//   function onRightIconPress() {
//     localStorage.removeItem("branchId")
//     navigate('/login', mainRef.current)
// }

// function goHome() {
//   navigate('/' ,mainRef.current);
//   setTimeout(() => {
//     endNav();
//   },);
// }

// const branchName = localStorage.getItem('branchName') || '';
  return (
    <>
      <main ref={mainRef} className="main-container">
        {/* <div style={{backgroundColor: 'red'}}> */}
        {/* <Header
                progress={undefined}
                // leftIcon={'tag'}
                leftText={branchName}
                rightIcon={'user'}
                // rightText={'MTD'}
                onLeftIconPress={goHome}
                onLogoPress={goHome}
                onRightIconPress={onRightIconPress}
            /> */}
        {/* </div> */}
        <AutoResponsive {...getAutoResponsiveProps(containerWidth)}
        >
          {(cardData || []).map((_, i) => {
            const widthDivider = getWidthDivider(device, _);
            let parentContainerWidth = containerWidth - 30;

            // For mobile 1 item margin (device screen into half), for others 3 item margins (divide screen into 4 equal parts)
            const width = (parentContainerWidth - (ITEM_MARGIN * (widthDivider - 1))) / widthDivider;

            let height = (windowSize().height - ITEM_MARGIN);
            if (_.heightMultiplier) {
              //@ts-ignore
              const heightMultiplier: number = getHeightMultiplier(_);
              height = (width * heightMultiplier) + (ITEM_MARGIN * (Math.round(heightMultiplier) - 1));
            }
            // Add itemMargin factor to total height of the card

            // const w2 = parentContainerWidth;
            console.log("parentContainerWidth " + parentContainerWidth);
            console.log("width " + width);
            // console.log("w2 " + w2);
            let style = {
              width: Math.round(width), // TODO: subtract small value just to give extra margin to settle
              height: Math.round(height),
              // width: _.w === 'w1' ? w1 : w2,
              // height: _.h === 'h2' ? 490 : _.w === 'w1' ? 240 : 490,
              borderRadius: 16
            };

            return (
              <a.div key={i}
                style={style}
              >
                <Card
                  ref={n => addCardNode(n, i)}
                  key={i}
                  index={i}
                  config={_}
                  className={`album item shadow`}
                  style={style}
                  height={style.height}
                  width={style.width}
                  onClick={handleCardClick} />
              </a.div>
            )
          }
          )}
        </AutoResponsive>

      </main>
      <TransitionGroup appear component={null}>
        {getOpenedCard(index) &&
          <Page
            index={index}
            data={cardData[index]}
            getOpenedCard={getOpenedCard}
            height={getOpenedCard(index).getStyle().height}
          />
        }

      </TransitionGroup>
    </>
  )
}

function getAutoResponsiveProps(containerWidth: any) {
  return {
    itemMargin: ITEM_MARGIN,
    containerWidth: containerWidth || document.body.clientWidth,
    // itemClassName: 'item',
    gridWidth: 1,
    transitionDuration: '.5',
    closeAnimation: false
  };
}


function getWidthSpecifier(device: string, item: any): string {
  if (device === "mobile") {
    return item.width.mobile;
  } else if (device === "tablet") {
    return item.width.tablet;
  } else {
    return item.width.desktop;
  }
}

function getWidthDivider(device: string, item: any): number {
  const widthSpecifier = getWidthSpecifier(device, item);
  if (widthSpecifier === 'quarter') {
    return 4;
  } else if (widthSpecifier === 'half') {
    return 2;
  } else {
    return 1;
  }
}

function getDevice() {
  //@ts-ignore
  let clientWidth = document.body.clientWidth;
  if (clientWidth <= 600) {
    return "mobile";
  } else if (clientWidth <= 1100) {
    return "tablet";
  } else {
    return "desktop";
  }
}

function getHeightMultiplier(card: any) {
  // card.heightMultiplier is either object or value (in which case it will apply to all devices).
  // if its not defined, then 1 is applied.
  let heightMultiplier = 1;
  if (typeof card.heightMultiplier === 'object' && card.heightMultiplier[getDevice()]) {
    heightMultiplier = card.heightMultiplier[getDevice()];
  }
  else if (typeof card.heightMultiplier === 'number') {
    heightMultiplier = card.heightMultiplier;
  }
  return heightMultiplier;
}

function windowSize() {
  var myWidth = 0, myHeight = 0;
  if (typeof (window.innerWidth) == 'number') {
    //Non-IE
    myWidth = window.innerWidth;
    myHeight = window.innerHeight;
  } else if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
    //IE 6+ in 'standards compliant mode'
    myWidth = document.documentElement.clientWidth;
    myHeight = document.documentElement.clientHeight;
  } else if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
    //IE 4 compatible
    myWidth = document.body.clientWidth;
    myHeight = document.body.clientHeight;
  }
  return { height: myHeight, width: myWidth }
  // window.alert( 'Width = ' + myWidth );
  // window.alert( 'Height = ' + myHeight );
}
