import React, { Component, useState, useEffect, useCallback, useRef } from 'react'
import List from 'screens/list/List';
import { navigate } from '@reach/router';
import { Header } from 'components/header';
import { useStore } from '../../index'
import { opsKpidata, qualityKpidata } from 'core/services/kpiService';
import {getBranches} from 'core/services/branchService'
import { getKpiCards } from './kpiSummaryCards';
import { CircularProgress } from '@material-ui/core';
import './Home.scss';
import DropDown from "components/dropDown/DropDown"


var branchData:any = [];

console.log("yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
//console.log(branchData)
/* [
  { value: 'Dallas', label: 'Dallas' },
  { value: 'Austin', label: 'Austin' },
  { value: 'McKinney', label: 'McKinney' },
  { value: 'Denton', label: 'Denton' },
  { value: 'Dallas', label: 'Dallas' },
  { value: 'Austin', label: 'Austin' },
]; */
const mtdData = [
  { value: 'Revenue', label: 'Revenue' },
  { value: 'Bookings', label: 'Bookings' },
  { value: 'Jobs', label: 'Jobs' },
  { value: 'Closings', label: 'Closings' },
  { value: 'price', label: 'price' },
  { value: 'Google', label: 'Google' },
];


export interface HomeProps {
  path?: string;
}

export interface HomeState {
}

const Home = ({ path, }: HomeProps) => {

  const endNav = useStore((state: any) => state.endNav)
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState([]);
  const [branches, setBranches] = useState([]);
// Create an scoped async function in the hook
  async function fetchData(branchId:any) {
    setLoading(true);
    const opsKpi = await opsKpidata(branchId);
    const qualityKpi = await qualityKpidata(branchId);
    
    setCards(getKpiCards(opsKpi, qualityKpi) as any);
    setLoading(false);
  }

  async function getBranchData(branchId:any) {
    
    branchData = await  getBranches(9);
    console.log("nnnnnnnnnnnnnnnnn")
    console.log(branchData);
  //  setBranches(branches as any);
    
  }


  useEffect(() => {
    getBranchData(localStorage.getItem("branchId"))
    fetchData(localStorage.getItem("branchId"));
    //getBranches(9);
   // getBranchData(9);
  }, [endNav]);

  const handleCardClick = useCallback(
    card => {
      //@ts-ignore
      navigate('/leaderboard', { state: { card: card } });
    },
    []
  )

  function onRightIconPress() {
    localStorage.clear();
    navigate('/login');
  }

  function goHome() {
    navigate('/');
    setTimeout(() => {
      endNav();
    }, 10);
  }

  function onChange(selectedOption: any) {
   
    console.dir(selectedOption)
    //alert(selectedOption.value)
    fetchData(selectedOption.value);
  }

  const branchName = localStorage.getItem('branchName') || '';

  return (
    <>
      <Header
        progress={undefined}
        leftText={branchName}
        rightIcon={'user'}
        onLeftIconPress={goHome}
        onLogoPress={goHome}
        onRightIconPress={onRightIconPress}
      />
{/*       {localStorage.getItem("userType")==="30"?
      <div className="kpiselect-container">
        <DropDown
          isMulti={false}
          className={'branch-select'}
          options={branchData}
          placeholder={'Branch'}
          onChange={onChange}
        />
        <DropDown
          className={'mtd-select'}
          options={mtdData}
          placeholder={'MTD'}

        />
      </div>:null
} */}
      {loading ?
        <CircularProgress size={'6em'} className="preloader" style={{ color: 'white' }} />
        :
        <List cards={cards} path="/" onCardClick={handleCardClick} />
      }
    </>
  )
}

export default Home;
