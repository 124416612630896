import React, { forwardRef, useRef, useState, useEffect } from 'react'
import ReactDOM, { render } from 'react-dom'
import { useStore } from '../../index'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
//@ts-ignore
import Slider from "react-slick";
import './Form.scss'
import { FormTitle } from 'components/form/formTitle';
import { IForm, IFormPage, IField } from 'shared/model/form';
import { FormGroup } from 'react-reactive-form';
import { FormSection } from 'components/form/formSection';
import { Header } from 'components/header';
import { Select } from 'components/select';
import { navigate } from '@reach/router';
//@ts-ignore
import SimpleLineIcon from 'react-simple-line-icons';

export interface FormProps {
    form: IForm;
    formGroup: FormGroup;
    index: number;
    disableSwipe?: boolean;
    hideNextButton?: boolean;
    hideProgressTabs?: boolean;
    backNavigation?: boolean;
    headerHeight?: string;
    width: number;
    ref?: any;
    submitted?: boolean;
    onSave?(): void;
    onDiscard?(): void;
    onValueChange?(value: any): void;
    onIndexChange?(index: number): void;
    onBack?(): void;
    onFooterTap?(): void;
    onLogoTap?(): void;
}

export interface FormState {
    progress: any;
    currentIndex: number;
    formPages: IFormPage[];
    footer: boolean;
}


class Form extends React.Component<FormProps, FormState> {
    sliderRef: any;

    constructor(props: FormProps) {
        super(props);
        this.state = {
            progress: (((this.props.form.currentFormPageIndex + 1) / this.props.form.formPages.length) * 100),
            currentIndex: this.props.index,
            formPages: this.props.form.visibleFormPages,
            footer: false
        };
        this.onFormChanges = this.onFormChanges.bind(this);
        this.beforeChange = this.beforeChange.bind(this);
    }

    // const Form = ({ form, formGroup, index, width, onValueChange, onIndexChange, onBack, submitted }: FormProps, sliderRef: any) => {
    // const sliderRef = useRef(null) // Our main dom Ref
    // const navigate = useStore((state: any) => state.navigate)
    // const endNav = useStore((state: any) => state.endNav)

    // const [formPages, setFormPages] = useState(form.visibleFormPages);
    // const [currentFormPage, setCurrentFormPage] = useState(form.currentFormPage);
    // const [currentIndex, setCurrentIndex] = useState(index);
    // const [progress, setProgress] = useState((((form.currentFormPageIndex + 1) / form.formPages.length) * 100));

    componentDidMount() {
        this.props.formGroup.valueChanges.subscribe(this.onFormChanges);
        navigate(`${'move'}?question=${this.props.form.currentFormPage.key}`);
    }

    componentDidUpdate(prevProps: FormProps) {
        if (this.props.formGroup !== prevProps.formGroup) {
            // debugger;
            this.props.formGroup.valueChanges.subscribe(this.onFormChanges);
        }
    }

    componentWillUnmount() {
        // debugger;
        this.props.formGroup.valueChanges.unsubscribe(this.onFormChanges);
        window.onpopstate = null;
        // if (this.props.onBack) {
        //     this.props.onBack();
        // }
    }

    goToNextPage(footerTap: boolean = false) {
        if (this.sliderRef) {
            this.sliderRef.slickNext();
        }
        if (footerTap && this.props.onFooterTap) {
            this.props.onFooterTap();
        }
    }

    goToPreviousPage() {
        if (this.sliderRef) {
            this.sliderRef.slickPrev();
        }
    }

    onFormChanges(value: any) {
        // debugger;
        this.setState({ formPages: [...this.props.form.getVisiblFormPages(this.props.form.formPages)] })

        // Automatically move to next page on single bubble selection.
        if (this.props.form.currentFormPage.valid && this.props.form.currentFormPage.goNextOnValid) {
            this.goToNextPage();
            let field: IField | IField[] = this.props.form.currentFormPage.fields[0] as IField;
            if (typeof field === 'object' && field.fieldType === 'singleSelect') {
                // setTimeout(() => {
                // }, 100)
            }
        }

        if (this.props.onValueChange) {
            this.props.onValueChange(value);
        }
    }

    beforeChange(oldIndex: number, newIndex: number) {

        let progress: number = ((newIndex + 1) / this.props.form.formPages.length) * 100;
        this.setState({ progress: progress });

        // Scroll view to top on web
        window.scrollTo(0, 0);

        let nextFormPage = this.props.form.formPages[newIndex];
        if (nextFormPage) {
            navigate(`${'move'}?question=${nextFormPage.key}`);
            window.onpopstate = (e: any) => {
                console.log("Browser back button presses");
                if (this.props.form.currentFormPageIndex !== 0) {
                    this.goToPreviousPage();
                } else {
                    if (this.props.onBack) {
                        this.props.onBack();
                    }
                }
            }

            this.setState({ footer: !!nextFormPage.footer })
        }
    }

    settings: any = {
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        lazyLoad: 'ondemand',
        // fade: true,
        // useCNumberLiteralSS: true,
        // useTransform: true,
        // cssEase: `cubic-bezier(0.39, 0.575, 0.565, 1)`,
        speed: 500,
        // draggable: true,
        // variableWidth: true,
        swipe: false,
        beforeChange: (oldIndex: number, newIndex: number) => this.beforeChange(oldIndex, newIndex),
        afterChange: (index: any) => {
            this.props.form.currentFormPageIndex = index;
            let progress: number = ((index + 1) / this.props.form.formPages.length) * 100;
            this.setState({ progress: progress });
            this.setState({ currentIndex: index });


            if (this.props.onIndexChange) {
                this.props.onIndexChange(index);
            }
        }
    };

    onBackPress() {
        if (this.props.form.currentFormPageIndex === 0) {
            if (this.props.onBack) {
                this.props.onBack();
            }
        } else if (this.sliderRef) {
            this.sliderRef.slickPrev();
        }
    }

    onLogoPress() {
        if (this.props.onLogoTap) {
            this.props.onLogoTap();
        }
    }

    // endNav();
    render() {
        return (
            <div className="form-section" style={{ width: this.props.width }}>
            <div className="header-section">
                <Header backIcon={true} onLogoPress={() => this.onLogoPress()} onBackPress={() => this.onBackPress()} progress={this.state.progress} leftIcon={'arrow-left'} rightIcon={'phone'} rightText={'Call Us'} />

            </div>
                <div className="section-padding">
                    <Slider
                        ref={(ref) => this.sliderRef = ref}
                        {...this.settings}
                    >
                        {this.state.formPages.map((formPage: IFormPage, index: number) => {
                            return (
                                <div key={index}>
                                    <FormTitle title={formPage.title} subTitle={formPage.subTitle}></FormTitle>
                                    <FormSection
                                        formPage={formPage}
                                    >
                                    </FormSection>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
                {this.state.footer &&
                    <div className="main-footer">
                        <Select label={"I'M NOT SURE"} onBubbleSelect={() => this.goToNextPage(true)} /><div className="footer-icon" onClick={() => this.goToNextPage(true)}><SimpleLineIcon name="arrow-right" /></div>
                    </div>
                }
            </div>
        )
    }
};

export default Form;